import React from "react"
import "./inner-intro.css"

const InnerIntro = ({ title, children, theme }) => {

    return <section id="intro" className={"inner-intro " + (theme ? theme : "")}>
        <div className="content">
            <h2>{title}</h2>
            {children}
        </div>
    </section>
}

export default InnerIntro