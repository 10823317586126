import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import InnerBanner from "../components/shared/inner-banner"
import InnerIntro from "../components/shared/inner-intro"
import banner from "../images/volunteer-banner-bg.jpg"
import Sections from "../components/volunteer/sections"
import ApplySection from "../components/shared/apply-section"
import HowToHelp from "../components/shared/how-to-help"
const VolunteerPage = () => {

  return <Layout colored_header={true}>
    <Seo title="Volunteer" />
    <InnerBanner image={banner} title="Volunteer" subtitle="Get Involved" />
    <InnerIntro title="Vacancy Announcement!">
        <div className="opening-jobs">
            <h4>We have openings for:</h4>
            <ul className="jobs">
              <li>Fosters</li>
              <li>Vendor Manager</li>
              <li>Computer Wizards</li>
              <li>Matchmakers</li>
              <li>Storyteller</li>
              <li>Inventory Coordinator</li>
              <li>Corporate Sponsor Coordinator</li>
              <li>Correspondence Coordinator</li>
              <li>Home Study Volunteers</li>
            </ul>
            <Link to="https://mailchi.mp/dwlabrescue/volunteers" target="_blank" className="btn-primary">Know More</Link>
        </div>
    </InnerIntro>
    <Sections.Section1/>
    <Sections.Section2/>
    <ApplySection theme="vol-apply">
      
           <Link className="btn-accent btn-volunteer" to="/volunteer-application/">Fill out volunteer form</Link>
    </ApplySection>
    <HowToHelp />
  </Layout>
}

export default VolunteerPage