import { Link } from "gatsby"
import React from "react"
import "./apply-section.css"
const ApplySection = ({ theme,children }) => {
    return <section className={`apply-section ${theme}`}>
        <div className="content">
             <div className="content-text">
                <p>Give a deserving Lab a “new leash on life” by becoming a volunteer. Contact Us for more information or fill out the Volunteer Form</p>
          </div>
            <div className="buttons">
              <Link className="btn-accent btn-contact" to="/contact/">Contact Us</Link>
               {children}
          </div>
        </div>
    </section>
}

export default ApplySection