import {  StaticImage } from "gatsby-plugin-image"
import React from "react"
import "./sections.css"

const Section1 = () => {

    return <section className="vol-sec1">
        <div className="content">
            <div className="content-text">
                <p>Volunteers, they are the lifeline for each and every lab that comes through DFWLRRC. Just look into the eyes of any of the rescues and you will see their eternal gratitude for that second chance at life…the second chance our volunteers gave to them. They are so thankful a volunteer listened to that inner voice that nudged them into signing up to do that home study, showing up to that Meet ’n Greet to talk with potential adopters or opening up their home to foster a Lab in need. Yes, each and every one of our volunteers makes a difference — no matter what the contribution. Without our volunteers we would not have been able to help over 2500 Labs since 2004.</p>
                <p>We have some of the best volunteers around! Still, we struggle to meet the demands with all the Labs that end up in shelters each and every week. The numbers are truly staggering, and the work to be done is never ending. What can you do to help?</p>
                <p className="large"><strong>While fostering is our greatest need, it isn’t for everyone. If you cannot foster, there are still so many ways you can help:</strong></p>
            </div>
            <div className="thumb">
                <StaticImage alt="volunteer" layout="constrained" src="../../images/volunteer-sec-1.jpg" />
            </div>
        </div>
    </section>

}

const Section2 = () => {
   const data = [
       {image: <StaticImage alt="Attend meet and greet" layout="constrained" src={`../../images/attend-a-meet-img.jpg`}/>,description:`<p><strong>Attend a Meet ’n Greet</strong> - These are the events where our foster dogs meet potential adopters, a step towards finding their forever homes. We are always in need of volunteers to transport and hold fosters, set up and break down the booth, take the dogs on potty breaks, fill water bowls and talk to potential adopters. We typically hold one to two of these events on the weekends. Each event requires at least 3-5 dogs and 5-10 volunteers. If you have a free time on a Saturday afternoon and can help, please let us know.</p>`},
       {image:<StaticImage alt="Participate in a Special Event" layout="constrained" src={`../../images/participate-in-a-special-event.jpg`}/>,description:`<p><strong>Participate in a Special Event</strong> - We take advantage of several special event opportunities throughout the year. These events help us raise funds for our foster dogs which helps cover their medical expenses. They not only benefit the Labs, they are also fun. We have recently participated in Wrapping for Retrievers, Washes and Wags and the Byron Nelson. We also host our annual Run for Retrievers and the Sporting Chance Golf Tournament.</p>`},
       {image:<StaticImage alt="Host a meet and greet" layout="constrained" src={`../../images/host-a-meet.jpg`}/>,description:`<p><strong>Host a Meet ’n Greet</strong> - In addition to the Meet ’n Greet we routinely hold, we are open to hosting in new locations where we can expand our reach. Do you know of a great new location in a pet store, at a neighborhood function such as a vendor fair or at a special event that you would like to host? If so, let us know. We have had recent success setting up in these types of locations and we are open to considering others.</p>`},
       {image:<StaticImage alt="Volunteer to Perform Home Studies" layout="constrained" src={`../../images/volunteer-to-perform.jpg`}/>,description:`<p><strong>Volunteer to Perform Home Studies in your Area</strong> - We receive applications from potential adopters from all over the Metroplex. Occasionally we do not have adequate coverage for some of the areas. This can result in delayed home studies for some applicants, something we want to avoid. If this interests you, please let us know. We can provide training with a seasoned volunteer until you are ready to do a few on your own.</p>`},
       {image:<StaticImage alt="Serve on a Committee or Serve" layout="constrained" src={`../../images/serve-on-a-committee.jpg`}/>,description:`<p><strong>Serve on a Committee or Serve as an Event Coordinator</strong> - We routinely post requests for committee members or event coordinators. These opportunities can range from fundraising to coordinating the volunteers, schedules and activities at special events such as our Run for Retrievers, the Sporting Chance Golf Tournament, or the Byron Nelson. These are fun ways to get involved and meet fellow volunteers.</p>
                        <p>Do you have others ideas or want to help but have a limited schedule? If so, just let us know what interests you and how much time you have. We can find the perfect volunteer opportunity to fit your schedule. Even volunteering an afternoon or two every once in a while can make all the difference for a lab in need.</p>`},
   ]
    return <section className="vol-sec2">
        <div className="content">
            <div className="items">
                {data && data.map((item,key)=>{
                    
                 return <div key={key} className="item">
                        <div className="thumb">
                            {item.image}
                        </div>
                        <div className="desc" dangerouslySetInnerHTML={{__html:item.description}}/>
                     </div>   
                })}
            </div>
        </div>
    </section>
}



const Sections = {
    Section1,
    Section2
}

export default Sections